import React from "react"
import { FacebookOutlined, TwitterOutlined } from "@ant-design/icons"
import { Avatar, Tooltip } from "antd"
import style from "./SocialSharing.module.scss"

const linkBuilders = {
  facebook: pageUrl => {
    const url = encodeURIComponent(pageUrl)
    return `https://facebook.com/sharer/sharer.php?u=${url}`
  },
  twitter: pageUrl => {
    const url = encodeURIComponent(pageUrl)
    return `https://twitter.com/intent/tweet/?url=${url}`
  },
}

const fireEvent = platform => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: `generic_event`,
      event_category: `Social`,
      event_action: `Share`,
      event_label: platform,
    })
  }
}

export default ({ pageUrl }) => {
  return (
    <div className={style.wrapper}>
      <Tooltip title="Share on Facebook!">
        <a
          className={style.link}
          href={linkBuilders.facebook(pageUrl)}
          onClick={() => fireEvent(`Facebook`)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Avatar
            className={style.facebook}
            icon={<FacebookOutlined />}
            shape="square"
            size="small"
          />
        </a>
      </Tooltip>
      <Tooltip title="Share on Twitter!">
        <a
          className={style.link}
          href={linkBuilders.twitter(pageUrl)}
          onClick={() => fireEvent(`Twitter`)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Avatar
            className={style.twitter}
            icon={<TwitterOutlined />}
            shape="square"
            size="small"
          />
        </a>
      </Tooltip>
    </div>
  )
}
