import React from "react"
import { graphql } from "gatsby"
import { Col, Row, Typography } from "antd"
import Shell from "../components/Shell/Shell"
import SEO from "@avldev/gatsby-theme-core-ui/src/components/SEO"
import Hero from "../../src/components/SharpHero/SharpHero"
import PackagesWidget from "../components/PackagesWidget/PackagesWidget"
import SocialSharing from "../components/SocialSharing/SocialSharing"
import ContentfulForm from "../components/ContentfulForm/ContentfulForm"
import ContentfulGallery from "../components/ContentfulGallery/ContentfulGallery"
import MediaContext from "@avldev/gatsby-theme-core-ui/src/components/MediaContext"

export default ({ data: { page, fallbackImage }, location }) => {
  const { width } = React.useContext(MediaContext)
  const { form, hero, imageGallery, includeForm } = page
  const img = hero ?? fallbackImage

  const showForm = form && includeForm
  const hasCopy = false
  // page.form &&
  // page.form.formCopy &&
  // page.form.formCopy.childMarkdownRemark &&
  // page.form.formCopy.childMarkdownRemark.html

  const heroHeight = width < 1200 ? `35vh` : `45vh`

  return (
    <Shell>
      <SEO
        meta={{
          description:
            (page.metaDescription && page.metaDescription.metaDescription) ||
            page.content.childMarkdownRemark.excerpt,
          image: img.fluid.src,
          imageAlt: img.description,
        }}
        title={page.metaTitle || `${page.title} - The Residences at Biltmore`}
        url={location.href}
      />
      <Hero customNode={<></>} contentfulAsset={img} height={heroHeight} />
      <Row align="middle" justify="center" type="flex">
        <Col xs={22} md={20} lg={16} xl={14} style={{ marginTop: 36 }}>
          <Typography.Title>{page.title}</Typography.Title>
          <Typography.Paragraph>
            <div
              id="page-content"
              className="md"
              dangerouslySetInnerHTML={{
                __html: page.content.childMarkdownRemark.html,
              }}
            />
          </Typography.Paragraph>
        </Col>
      </Row>
      {imageGallery || showForm ? (
        <Row
          align="start"
          gutter={[32, 36]}
          justify="center"
          style={{
            background: `rgb(231,231,226)`,
            marginTop: 24,
            paddingBottom: 48,
            paddingTop: 24,
          }}
          type="flex"
        >
          {imageGallery && showForm ? (
            <>
              {hasCopy ? (
                <Col xs={22} sm={10} md={8} lg={8} xl={6}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: page.form.formCopy.childMarkdownRemark.html,
                    }}
                  />
                </Col>
              ) : null}
              <Col xs={22} sm={16} md={16} lg={14} xl={18}>
                <ContentfulForm ignoreCopy {...page.form} />
              </Col>
              <Col xs={24}>
                <ContentfulGallery
                  images={imageGallery.images}
                  type="fullscreen"
                />
              </Col>
            </>
          ) : imageGallery && !showForm ? (
            <Col xs={24}>
              <ContentfulGallery
                images={imageGallery.images}
                type="fullscreen"
              />
            </Col>
          ) : !imageGallery && showForm ? (
            <Col xs={22} md={20} lg={16} xl={14}>
              <ContentfulForm {...page.form} />
            </Col>
          ) : null}
        </Row>
      ) : null}
      <Row style={{ marginTop: 36 }}>
        <Col span={23}>
          <SocialSharing pageUrl={location.href} />
        </Col>
      </Row>
      <PackagesWidget />
    </Shell>
  )
}

export const query = graphql`
  query pageContent($id: String!) {
    page: contentfulContentPage(id: { eq: $id }) {
      content {
        childMarkdownRemark {
          html
        }
      }
      form {
        ...FormData
      }
      hero {
        ...HeroFluid
      }
      imageGallery {
        ...ImageGallery
      }
      includeForm
      metaTitle
      metaDescription {
        metaDescription
      }
      slug
      title
      wpUri
    }
    fallbackImage: contentfulAsset(title: { eq: "RAB - Home Hero 1" }) {
      ...HeroFluid
    }
  }
`
