import React from "react"
import { Button, Col, Form, Modal, Row } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import * as Components from "./Components"
import * as Fields from "./Fields"
import fieldTypes from "./fieldTypes"
// import { getPPCString } from "../../utils/ppcBuddy"
import { antdHelpers } from "@avldev/gatsby-active-campaign-forms"
import { formUrl } from "../../../config/forms"

const FIELD_SIZES = {
  Checkboxes: { xs: 24, md: 24, lg: 24 },
  Date: { xs: 24, md: 12, lg: 12 },
  Name: { xs: 24, md: 12, lg: 12 },
  Email: { xs: 24, md: 12, lg: 12 },
  "Phone Number": { xs: 24, md: 12, lg: 12 },
  "Number (plain)": { xs: 16, md: 12, lg: 12 },
  "Text Area": { xs: 24, md: 24, lg: 24 },
}

export default ({
  acHiddenInputs,
  contentfulfields,
  formCopy,
  ignoreCopy,
  name,
  submitButtonLabel = `Submit`,
  successMessageBody = `We'll be in touch shortly.`,
  successMessageHeader = `Success!`,
}) => {
  const [form] = Form.useForm()
  const [submitting, setSubmitting] = React.useState(false)
  const [result, setResult] = React.useState(null)
  const [modalVis, setModalVis] = React.useState(false)
  const nameRef = React.useRef({ name: `` })
  const formWrapperRef = React.useRef(null)

  React.useEffect(() => {
    if (submitting && result) {
      setModalVis(true)
    }
  }, [result, submitting])

  React.useEffect(() => {
    if (modalVis) {
      if (result === 200) {
        form.resetFields()
        if (window.dataLayer) {
          window.dataLayer.push({
            event: `generic_event`,
            event_category: `Contact`,
            event_action: `Submit`,
            event_label: name.replace(`Contact`, `Page`),
          })
        }
      }
      setSubmitting(false)
    } else if (!submitting && !modalVis && result) {
      setResult(null)
    }
  }, [form, modalVis, name, result, submitting])

  const handleSubmit = async () => {
    const { current } = formWrapperRef
    const _form = current?.querySelector(`form`)
    if (!_form) return

    const onSubmitStart = () => {
      setSubmitting(true)
    }

    const onSubmitEnd = status => {
      setResult(status)
    }

    antdHelpers.onSubmit(
      _form,
      { onSubmitStart, onSubmitEnd },
      process.env.NODE_ENV
    )
  }

  const handleValueChange = data => {
    if (data.name) {
      nameRef.current.name = data.name.split(` `)[0]
    }
  }

  const hasCopy =
    formCopy &&
    formCopy.childMarkdownRemark &&
    formCopy.childMarkdownRemark.html
  const formLayout = { span: 24 }

  return (
    <Row align="top" gutter={32} justify="center" type="flex">
      <Modal
        bodyStyle={{ padding: 12 }}
        centered
        closable={false}
        footer={null}
        visible={modalVis}
      >
        <Components.FormResult
          result={result}
          header={successMessageHeader.replace(/%name%/g, nameRef.current.name)}
          body={
            <div
              dangerouslySetInnerHTML={{
                __html: successMessageBody.childMarkdownRemark.html.replace(
                  /%name%/g,
                  nameRef.current.name
                ),
              }}
            />
          }
          extra={[
            <Button
              icon={<CloseOutlined />}
              key={1}
              onClick={() => setModalVis(false)}
              size="large"
              type="primary"
            >
              Close
            </Button>,
          ]}
        />
      </Modal>
      {!ignoreCopy && hasCopy ? (
        <Col xs={24} md={12} style={{ marginBottom: 24 }}>
          <div
            dangerouslySetInnerHTML={{
              __html: formCopy.childMarkdownRemark.html,
            }}
          />
        </Col>
      ) : null}
      <Col
        xs={24}
        md={!ignoreCopy && hasCopy ? 12 : 24}
        style={{ maxWidth: 550 }}
        ref={formWrapperRef}
      >
        <Components.FormSpinner condition={submitting}>
          <h2>{name}</h2>
          <Form
            action={formUrl}
            layout="vertical"
            form={form}
            wrapperCol={formLayout}
            onFinish={handleSubmit}
            onValuesChange={handleValueChange}
            size="large"
            validateTrigger="onValuesChange"
          >
            {acHiddenInputs?.acHiddenInputs && (
              <div
                dangerouslySetInnerHTML={{
                  __html: acHiddenInputs.acHiddenInputs,
                }}
              />
            )}
            <Row align="middle" gutter={16} justify="start">
              {contentfulfields.map(
                (
                  {
                    fieldType,
                    name,
                    acName,
                    options,
                    placeholderText,
                    required,
                    validationErrorMessage,
                    value,
                  },
                  index
                ) => {
                  const Field = Fields[fieldTypes[fieldType]]
                  return (
                    <Col
                      key={index}
                      xs={FIELD_SIZES[fieldType].xs}
                      md={FIELD_SIZES[fieldType].md}
                      lg={FIELD_SIZES[fieldType].lg}
                    >
                      <Field
                        errorMessage={validationErrorMessage}
                        name={name}
                        acName={acName}
                        options={options}
                        placeholder={placeholderText}
                        required={required}
                        value={value}
                      />
                    </Col>
                  )
                }
              )}
              <Col
                xs={{ span: 18, offset: 0 }}
                sm={{ span: 16, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 10, offset: 14 }}
                // xl={{ span: 6, offset: 18 }}
              >
                <Fields.SubmitButton label={submitButtonLabel} />
              </Col>
            </Row>
          </Form>
        </Components.FormSpinner>
      </Col>
    </Row>
  )
}
