export default {
  Checkboxes: `CheckboxGroup`,
  Date: `Date`,
  Email: `Email`,
  Name: `Name`,
  "Number (currency)": `Currency`,
  "Number (plain)": `Number`,
  "Phone Number": `Phone`,
  "Radio Buttons": `RadioGroup`,
  "Text Area": `Message`,
  Text: `Text`,
  Time: `Time`,
}
