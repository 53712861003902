import React from "react"
import { Carousel } from "antd"
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons"
import Img from "gatsby-image"
import MediaContext from "@avldev/gatsby-theme-core-ui/src/components/MediaContext"
import style from "./ContentfulGallery.module.scss"

const NextArrow = ({ onClick }) => (
  <button onClick={onClick} className={style.arrow} style={{ right: 8 }}>
    <ArrowRightOutlined />
  </button>
)

const PrevArrow = ({ onClick }) => (
  <button onClick={onClick} className={style.arrow} style={{ left: 8 }}>
    <ArrowLeftOutlined />
  </button>
)

const slidesToShow = width => {
  if (width <= 540) return 1
  else if (width <= 1500) return 2
  return 3
}

export default ({ images, type = `single` }) => {
  const ref = React.createRef()
  const { reduceMotion, width } = React.useContext(MediaContext)

  const numSlides = type === `single` ? 1 : slidesToShow(width)

  return (
    <Carousel
      adaptiveHeight
      arrows={true}
      className={style[type]}
      draggable
      effect={reduceMotion && numSlides === 1 ? `fade` : `scrollx`}
      nextArrow={<NextArrow onClick={ref.next} />}
      prevArrow={<PrevArrow onClick={ref.prev} />}
      ref={ref}
      slidesToShow={numSlides}
      speed={reduceMotion && numSlides > 1 ? 0 : 500}
      style={style}
    >
      {images.map(({ description, fluid }, index) => (
        <div key={index}>
          <Img
            alt={description}
            className={style.image}
            fluid={fluid}
            style={{ margin: slidesToShow > 1 ? 12 : 0 }}
          />
        </div>
      ))}
    </Carousel>
  )
}
