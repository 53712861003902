import React from "react"
import { Result, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

export const FormSpinner = ({ children, condition, icon }) => (
  <Spin indicator={icon || <LoadingOutlined />} spinning={condition}>
    {children}
  </Spin>
)

export const FormResult = ({ result, header, body, extra }) => {
  const status = result === 200 ? "success" : "error"
  const error = status === `error`
  return (
    <Result
      status={status}
      title={error ? `Uh oh...` : header}
      subTitle={
        error ? `Something's gone wrong. Please try again shortly.` : body
      }
      extra={extra}
    />
  )
}
