import React from "react"
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  TimePicker,
} from "antd"
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  MessageOutlined,
  SendOutlined,
  HomeOutlined,
  NumberOutlined,
} from "@ant-design/icons"
import moment from "moment"
import { fieldNames } from "../../../config/forms"

const iconStyle = {
  style: {
    marginRight: 4,
    opacity: 0.5,
  },
}

/**
 * AuomationID
 * Name: automation_id
 * Required: false
 */
export const AutomationID = ({ id, name }) => (
  <Form.Item
    initialValue={id}
    name={name || "automation_id"}
    style={{ display: `none` }}
  >
    <Input />
  </Form.Item>
)

/**
 * Misc Dynamic Values
 * Name: <user-defined>
 * Required: false
 */
export const DynamicValues = ({ values }) =>
  Object.keys(values).map((key, index) => (
    <Form.Item
      key={index}
      initialValue={values[key] || ""}
      name={key}
      style={{ display: `none` }}
    >
      <Input />
    </Form.Item>
  ))

/**
 * Form Name (for Core)
 * Name: form_name
 * Required: false (but required by form generator)
 */
export const FormName = ({ fieldName, formName }) => (
  <Form.Item
    initialValue={formName}
    name={fieldName || "form_name"}
    style={{ display: `none` }}
  >
    <Input />
  </Form.Item>
)

/**
 * Name
 * Name: [name prop]
 * Required: false
 */
export const Text = ({
  props,
  name,
  acName,
  placeholderText,
  required,
  errorMessage = `Something doesn't seem right here...`,
  value,
}) => (
  <Form.Item
    {...props}
    initialValue=""
    // label={name}
    name={value || name.toLowerCase()}
    rules={[
      {
        required: required || false,
        message: `This field is required`,
      },
      {
        type: `string`,
        message: errorMessage,
      },
    ]}
    value
  >
    <Input placeholder={placeholderText || name} name={acName} />
  </Form.Item>
)

/**
 * Name
 * Name: fullname
 * Required: false
 */
export const Name = ({
  props,
  placeholder = `Full Name`,
  required,
  errorMessage = `Something doesn't seem right here...`,
  value,
}) => (
  <Form.Item
    {...props}
    initialValue=""
    rules={[
      {
        required: required || false,
        message: `This field is required`,
      },
      {
        type: `string`,
        message: errorMessage,
      },
    ]}
  >
    <Input
      name="fullname"
      placeholder={placeholder}
      prefix={<UserOutlined {...iconStyle} />}
    />
  </Form.Item>
)

/**
 * Email
 * Name: email
 * Required: true
 */
export const Email = ({
  props,
  placeholder = `Email`,
  errorMessage = `This doesn't appear to be a valid email address`,
  value,
  required = true,
}) => {
  return (
    <Form.Item
      {...props}
      initialValue=""
      // label={name}
      name="email"
      rules={[
        {
          required: true,
          message: `This field is required`,
        },
        {
          type: "email",
          message: errorMessage,
        },
      ]}
    >
      <Input
        name="email"
        placeholder={placeholder}
        prefix={<MailOutlined {...iconStyle} />}
      />
    </Form.Item>
  )
}

export const Number = ({
  props,
  name,
  acName,
  placeholder,
  required,
  errorMessage,
  value,
}) => (
  <Form.Item
    {...props}
    initialValue=""
    // label={name}
    name={value || name.toLowerCase() || "number"}
    rules={[
      {
        required: required || false,
        message: `This field is required`,
      },
    ]}
  >
    <Input
      defaultValue=""
      name={acName}
      placeholder={placeholder}
      prefix={<NumberOutlined {...iconStyle} />}
    />
  </Form.Item>
)

const phoneNumberPattern =
  /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/

/**
 * Phone
 * Name: phone
 * Required: false
 */
export const Phone = ({
  props,
  name,
  placeholder = `Phone`,
  required = false,
  errorMessage = `This doesn't appear to be a valid phone number`,
  value,
}) => (
  <Form.Item
    {...props}
    initialValue=""
    // label={name}
    name="phone"
    rules={[
      {
        required: required,
        message: `This field is required`,
      },
      {
        validator: (_, value) => {
          if (required) {
            return phoneNumberPattern.test(value)
              ? Promise.resolve()
              : Promise.reject()
          }
          return Promise.resolve()
        },
        message: errorMessage,
      },
    ]}
  >
    <Input
      name="phone"
      placeholder={placeholder}
      prefix={<PhoneOutlined {...iconStyle} />}
    />
  </Form.Item>
)

/**
 * Message
 * Name: message
 * Required: false
 */
export const Message = ({
  props,
  name,
  acName,
  placeholder = `How can we help you?`,
  required = false,
  rows,
  errorMessage = `This field is required`,
  value,
}) => (
  <Form.Item
    {...props}
    initialValue=""
    name={acName || fieldNames.message}
    // label={name}
    rules={[
      {
        required: required,
        message: errorMessage,
      },
    ]}
  >
    <Input.TextArea
      name={acName || fieldNames.message}
      placeholder={placeholder}
      prefix={<MessageOutlined {...iconStyle} />}
      rows={rows || 4}
    />
  </Form.Item>
)

export const CheckboxGroup = ({
  props,
  name,
  acName,
  required = false,
  errorMessage = `Please select at least one option`,
  value,
  options,
}) => (
  <Form.Item
    {...props}
    label={name}
    rules={[{ required, message: errorMessage }]}
    name={acName}
  >
    <Checkbox.Group name={acName} style={{ marginBottom: 8 }}>
      <Row>
        {options.map((option, index) => (
          <Col key={index} span={12}>
            <Checkbox value={option}>{option}</Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  </Form.Item>
)

export const RadioGroup = ({
  props,
  name,
  acName,
  required,
  errorMessage,
  value,
  options,
}) => (
  <Form.Item
    {...props}
    initialValue=""
    label={`${name}:`}
    rules={[
      {
        required: required || false,
        message: errorMessage || `This field is required`,
      },
    ]}
    style={{ marginTop: -12 }}
  >
    <Radio.Group name={acName}>
      <Row>
        {options.map((option, index) => (
          <Col key={index} span={12}>
            <Radio value={option}>{option}</Radio>
          </Col>
        ))}
      </Row>
    </Radio.Group>
  </Form.Item>
)

export const Address = ({
  icon,
  props,
  name,
  acName,
  placeholder,
  required,
  rules,
}) => (
  <Form.Item
    {...props}
    initialValue=""
    // label={name}
    rules={rules || [{ required: required || false }]}
  >
    <Input
      name={acName}
      placeholder={placeholder || "Address"}
      prefix={icon || <HomeOutlined {...iconStyle} />}
    />
  </Form.Item>
)

export const Date = ({
  disabledDates,
  format = `ddd MMM Do`,
  props,
  name = `date`,
  acName,
  onChange,
  required = true,
  placeholder = `Select Date`,
  rules,
  value,
}) => {
  const _onChange = onChange || (() => null)
  const _disabledDates =
    disabledDates || (cur => cur && cur < moment().endOf("day"))

  return (
    <Form.Item
      {...props}
      // label={name}
      name={acName}
      rules={
        rules || [{ required: required, message: `This field is required` }]
      }
    >
      <DatePicker
        disabledDate={_disabledDates}
        format={format}
        onChange={_onChange}
        placeholder={placeholder}
        showToday={false}
        style={{ width: `100%` }}
        name={acName}
      />
    </Form.Item>
  )
}

export const Time = ({
  defaultTime,
  disabledHours,
  format,
  hourStep,
  minuteStep,
  name,
  acName,
  required,
  rules,
}) => {
  const _disabledHours =
    disabledHours ||
    (() => [18, 19, 20, 21, 22, 23, 24, 0, 1, 2, 3, 4, 5, 6, 7, 8])

  const _format = format || "HH:mm"
  return (
    <Form.Item
      // label={name}
      name={acName}
      rules={rules || [{ required: required || false }]}
    >
      <TimePicker
        defaultValue={defaultTime || moment("10:00", _format)}
        disabledHours={_disabledHours}
        format={_format}
        hideDisabledOptions
        hourStep={hourStep || 1}
        minuteStep={minuteStep || 30}
        showNow={false}
        use12Hours
        name={acName}
      />
    </Form.Item>
  )
}

export const TimeSelect = ({ props, name, acName, required, rules }) => {
  const hours = [9, 10, 11, 12, 13, 14, 15, 16, 17]
  let optionNum = 0

  return (
    <Form.Item
      {...props}
      // label={name}
      rules={rules || [{ required: required || false }]}
      style={{ width: 110 }}
    >
      <Select placeholder="Time" name={acName}>
        {hours.map(hour => {
          const meridian = hour < 12 ? "AM" : "PM"
          const _hour = hour > 12 ? hour - 12 : hour
          const t1 = `${_hour}:00 ${meridian}`
          const t2 = `${_hour}:30 ${meridian}`

          return (
            <>
              <Select.Option key={++optionNum} value={t1}>
                {t1}
              </Select.Option>
              <Select.Option key={++optionNum} value={t2}>
                {t2}
              </Select.Option>
            </>
          )
        })}
      </Select>
    </Form.Item>
  )
}

export const Scheduler = () => {
  return (
    <Space direction="horizontal" style={{ width: `100%` }}>
      <Date />
      <TimeSelect />
    </Space>
  )
}

export const SyncDeal = ({ value }) => (
  <Form.Item
    initialValue={value}
    name={"sync_deal"}
    style={{ display: `none` }}
  >
    <Input />
  </Form.Item>
)

export const SubmitButton = ({ block, icon, label, layout, type }) => (
  <Form.Item>
    <Button
      block={block || true}
      htmlType="submit"
      icon={icon || <SendOutlined />}
      type={type || "primary"}
    >
      {label || "Submit"}
    </Button>
  </Form.Item>
)
